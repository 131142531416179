.footer {
  margin-top: 1rem;
  padding: 1rem;
  /* background-color: rgb(235, 195, 64); */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: rgb(70, 70, 70);
}
.sso {
  background-color: #ebf8f2;
  padding: 10px;
  border-radius: 5px;
}
.main {
  align-items: center;
  display: flex;
  background-color: whitesmoke;
  padding: 6px;
  border-radius: 5px;
}
.badge {
  color: #637381;
  padding: 7px;
  font-size: 19px;
  border-radius: 4px;
}
.singleName {
  color: #637381;
  padding: 7px;
  font-size: 19px;
  border-radius: 4px;
  background-color: #dcdcdc;
  width: 45px;
  font-weight: bold;
}
.headtext {
  float: left;
  color: #00ab55;
}
.testDiv {
  display: flex;
  justify-content: space-around;
}
.firstDiv{ 
  /* margin-top: 8px; */
  margin-right: 10px;
}

.logoImgage {
  width: 100px;
}