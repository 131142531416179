.middle {
    transform: translateY(30%);
}
.lgblock {
    vertical-align: middle;
}

.logo_mainPage {
    margin-top: -500px;
    z-index: 100;
}

  